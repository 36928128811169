import React, { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { motion, useScroll, useTransform } from 'framer-motion'
import styles from '../styles/Landing.module.css'
import photo from '../img/photo.png'
import { contacts } from '../content'
import Typewriter from 'typewriter-effect'

export default function Landing() {
    const isDesktop = useMediaQuery({
        query: '(min-width: 1224px)'
    })

    const { scrollY } = useScroll()
    const opacity = useTransform(scrollY, [0, 500], [1, 0])

    return (
        <motion.div style={{ opacity }}>
            {isDesktop && <Desktop />}
            {!isDesktop && <Mobile />}
        </motion.div>
    )
}

function Desktop() {
    const [year, setYear] = useState("")
    const [date, setDate] = useState("")
    const [time, setTime] = useState("")

    useEffect(() => {
        const date = new Date();
        const split = date.toString().split(" ")
        setYear(`${split[3]}`)
        setDate(`${split[1]} ${split[2]}`)
        setTime(`${split[4].split(':')[0]}:${split[4].split(':')[1]}`)
    }, [])

    return (
        <div className={styles.DesktopContainer}>
            <div className={styles.nonlinks}>
                <h1 className={styles.desktopDate}>
                    <Typewriter options={{cursor: "", delay: 80}}
                        onInit={(typewriter) => {
                        typewriter.typeString(date)
                            .start()
                        }}
                    />
                </h1>
                <h1 className={styles.desktopTime}>
                    <Typewriter options={{cursor: "", delay: 80}}
                        onInit={(typewriter) => {
                        typewriter.typeString(time)
                            .start()
                        }}
                    />
                </h1>
                <h1 className={styles.desktopYear}>
                    <Typewriter options={{cursor: "", delay: 80}}
                        onInit={(typewriter) => {
                        typewriter.typeString(year)
                            .start()
                        }}
                    />
                </h1>
                <h1 className={styles.desktopName}>
                    <Typewriter options={{cursor: "", delay: 70}}
                        onInit={(typewriter) => {
                        typewriter.typeString(`MATTHEW<br />NANAS`)
                            .start()
                        }}
                    />
                </h1>
                <h1 className={styles.desktopSWE}>
                    <Typewriter options={{cursor: "", delay: 50}}
                        onInit={(typewriter) => {
                        typewriter.typeString(`SOFTWARE<br />ENGINEER`)
                            .start()
                        }}
                    />
                </h1>
                <h1 className={styles.desktopDescription}>
                    <Typewriter options={{cursor: "", delay: 10}}
                        onInit={(typewriter) => {
                        typewriter.typeString(`building things<br />@ washington dc area`)
                            .start()
                        }}
                    />
                </h1>
                <img src={photo} alt="Me" className={styles.desktopPhoto}/>
            </div>
            <div className={styles.desktopContact}>
                {contacts.map((entry) => (
                    <a key={entry.type} href={entry.value} target="_blank" rel="noreferrer">
                        <span>
                            <Typewriter options={{cursor: "", delay: 80}}
                                onInit={(typewriter) => {
                                typewriter.typeString(entry.type)
                                    .start()
                                }}
                            />
                        </span>
                    </a>
                ))}
            </div>
        </div>
    )
}

function Mobile() {
    return (
        <div className={styles.MobileContainer}>
            <div className={styles.nonlinks}>
                <h1 className={styles.mobileName}>
                    <Typewriter options={{cursor: "", delay: 70}}
                        onInit={(typewriter) => {
                        typewriter.typeString(`MATTHEW<br />NANAS`)
                            .start()
                        }}
                    />
                </h1>
                <h1 className={styles.mobileSWE}>
                    <Typewriter options={{cursor: "", delay: 50}}
                        onInit={(typewriter) => {
                        typewriter.typeString(`SOFTWARE<br />ENGINEER`)
                            .start()
                        }}
                    />
                </h1>
                <h1 className={styles.mobileDescription}>
                    <Typewriter options={{cursor: "", delay: 10}}
                        onInit={(typewriter) => {
                        typewriter.typeString(`building things<br />@ washington dc area`)
                            .start()
                        }}
                    />
                </h1>
                <img src={photo} alt="Me" className={styles.mobilePhoto}/>
            </div>
            <div className={styles.mobileContact}>
                {contacts.map((entry) => (
                    <a key={entry.type} href={entry.value} target="_blank" rel="noreferrer">
                        <span>
                            <Typewriter options={{cursor: "", delay: 80}}
                                onInit={(typewriter) => {
                                typewriter.typeString(entry.type)
                                    .start()
                                }}
                            />
                        </span>
                    </a>
                ))}
            </div>
        </div>
    )
}