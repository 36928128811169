export const contacts = [
    {
        type: "email",
        value: "mailto:mnanas@umd.edu"
    },
    {
        type: "github",
        value: "https://www.github.com/matthewnanas/"
    },
    {
        type: "linkedin",
        value: "https://www.linkedin.com/in/matthewnanas/"
    },
]