import React from 'react'
import { useMediaQuery } from 'react-responsive'
import styles from '../styles/About.module.css'
import { motion, useScroll, useTransform } from 'framer-motion';


export default function About(): React.ReactElement {
    const isDesktop = useMediaQuery({
        query: '(min-width: 1224px)'
    });

    const { scrollY } = useScroll();
    const opacity = useTransform(scrollY, [300, 800], [0, 1]); // Adjusted range for fade-in

    return (
        <motion.div style={{ opacity }}>
            {isDesktop && <Desktop />}
            {!isDesktop && <Mobile />}
        </motion.div>
    );
}

function Desktop(): React.ReactElement {
    return (
        <div className={styles.DesktopContainer}>
            <h1 className={styles.AboutHeading}>about</h1>
            <p className={styles.AboutText}>
                my name is matthew nanas --- i am a self taught computer dude who fell into this rabbithole in fourth grade. back then i started with simple websites. my curiousity led me to learn python and dabble in cybersecurity. i remember installing my first kali linux vm in middle school and jumping on the platform hackthebox where i ranked pretty well (at least in my eyes). for some time throughout high school, i freelanced working on sneaker and nft bots where i managed to contribute to some of the most coveted software within the scene (have now left to pursue other stuff). blah blah blah, let's just talk
            </p>
            <h1 className={styles.AboutHeading}>skills</h1>
            <ul className={styles.AboutText}>
                <li>frontend: react & nextjs, svelte, html, css, bootstrap, tailwind</li>
                <li>backend: nodejs, python, java, go, rust (mehhh)</li>
                <li>cloud: aws, azure, firebase, supabase, google cloud</li>
                <li>cybersec: forensics (wireshark), metasploit, recon (nmap), reverse engineering (debugging, ghidra)</li>
                <li>other: flask, express, neo4j</li>
            </ul>
            <h1 className={styles.AboutHeading}>experience</h1>
            <div className={styles.timeline}>
                <ul className={styles.AboutText}>
                    <li>june 2024 - current: data engineer intern @ saic (space and national intel)</li>
                    <li>jan 2023 - current: swe intern @ umd geog (web3 x geospatial)</li>
                    <li>may 2023 - aug 2023: research intern @ us army research lab</li>
                    <li>aug 2020 - dec 2021: swe intern @ top down systems corporation (frontend)</li>
                    <li>2019 - 2023: freelance, sneakerbots, nft bots</li>
                </ul>
            </div>
            <h1 className={styles.AboutHeading}>more</h1>
            <p className={styles.AboutText}>
                projects can be found on my <a href="https://www.linkedin.com/in/matthewnanas/" target="_blank" rel="noreferrer">linkedin</a> and <a href="https://devpost.com/matt0bananas4" target="_blank" rel="noreferrer">devpost</a>
            </p>
        </div>
    )
}

function Mobile(): React.ReactElement {
    return (
        <div className={styles.MobileContainer}>
            <h1 className={styles.AboutHeadingMobile}>about</h1>
            <p className={styles.AboutTextMobile}>
                my name is matthew nanas --- i am a self taught computer dude who fell into this rabbithole in fourth grade. back then i started with simple websites. my curiousity led me to learn python and dabble in cybersecurity. i remember installing my first kali linux vm in middle school and jumping on the platform hackthebox where i ranked pretty well (at least in my eyes). for some time throughout high school, i freelanced working on sneaker and nft bots where i managed to contribute to some of the most coveted software within the scene (have now left to pursue other stuff). blah blah blah, let's just talk
            </p>
            <h1 className={styles.AboutHeadingMobile}>skills</h1>
            <ul className={styles.AboutTextMobile}>
                <li>frontend: react & nextjs, svelte, html, css, bootstrap, tailwind</li>
                <li>backend: nodejs, python, java, go, rust (mehhh)</li>
                <li>cloud: aws, azure, firebase, supabase, google cloud</li>
                <li>cybersec: forensics (wireshark), metasploit, recon (nmap), reverse engineering (debugging, ghidra)</li>
                <li>other: flask, express, neo4j</li>
            </ul>
            <h1 className={styles.AboutHeadingMobile}>experience</h1>
            <div className={styles.timeline}>
            <ul className={styles.AboutTextMobile}>
                    <li>june 2024 - current: data engineer intern @ saic (space and national intel)</li>
                    <li>jan 2023 - current: swe intern @ umd geog (web3 x geospatial)</li>
                    <li>may 2023 - aug 2023: research intern @ us army research lab</li>
                    <li>aug 2020 - dec 2021: swe intern @ top down systems corporation (frontend)</li>
                    <li>2019 - 2023: freelance, sneakerbots, nft bots</li>
                </ul>
            </div>
            <h1 className={styles.AboutHeadingMobile}>~$/ cat projects.txt</h1>
            <p className={styles.AboutTextMobile}>
                projects can be found on my <a href="https://www.linkedin.com/in/matthewnanas/" target="_blank" rel="noreferrer">linkedin</a> and <a href="https://devpost.com/matt0bananas4" target="_blank" rel="noreferrer">devpost</a>
            </p>
        </div>
    )
}